import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { useScrollState } from "scrollmonitor-hooks";

import Layout from "../components/layout";
// import CardBlog from "../components/CardBlog";
//
// import ImageCover from "../images/photos/cover-how-to-hire-ux-designer.png";
// import ImageFirstScreen from "../images/photos/0---Insiders-Guide-How-to-Find-Evaluate-and-Hire-UX-Designers.png";
// import ImageSecondScreen from "../images/photos/1---Where-Can-You-Find-UX-Designers---Targeted-Outreach.png";
// import ImageThirdScreen from "../images/photos/2---Hire-UX-Designer-from-Online-UX-Design-Communities.png";
// import ImageFourthScreen from "../images/photos/3---Find-User-Experience-Designer-from-UX-Job-Boards.png";
// import ImageFifthScreen from "../images/photos/4---How-Do-You-Evaluate-a-UX-Designer.png";
// import ImageSixthScreen from "../images/photos/5---What-Else-Should-You-Know-Before-Hiring-a-UX-Designer.png";
// import ImageSeventhScreen from "../images/photos/6---Interview-Questions-to-Ask-While-Hiring-a-UX-Designer.png";
// import ImageEighthScreen from "../images/photos/7 - interview questions for UX designer.png";
//
// import ImageMistake1 from "../images/photos/mistake-1.png";
// import ImageMistake2 from "../images/photos/mistake-2.png";
// import ImageMistake3 from "../images/photos/mistake-3.png";
// import ImageMistake4 from "../images/photos/mistake-4.png";
// import ImageMistake5 from "../images/photos/mistake-5.png";
// import ImageMistake6 from "../images/photos/mistake-6.png";
//
// import ImageChapter1 from "../images/photos/tmb-1.png";
// import ImageChapter2 from "../images/photos/tmb-2.png";
// import ImageChapter3 from "../images/photos/tmb-3.png";
// import ImageChapter4 from "../images/photos/tmb-4.png";
// import ImageChapter5 from "../images/photos/tmb-5.png";
//
// import ImageLeanUx from "../images/photos/main-pagep-lean-ux.png";
// import ImageArticle from "../images/photos/article-9.jpg";
//
// import IconDifference from "../images/icons/theme/design/difference.svg";
// import IconCrop from "../images/icons/theme/design/crop.svg";
// import IconMagic from "../images/icons/theme/design/magic.svg";
// import IconPenRuler from "../images/icons/theme/design/pen-ruler.svg";
// import IconSocialTwitter from "../images/icons/social/twitter.svg";
// import IconLike from "../images/icons/theme/general/like.svg";
// import IconPlus from "../images/icons/theme/code/plus.svg";
// import IconMinus from "../images/icons/theme/code/minus.svg";
// import IconClose from "../images/icons/theme/navigation/close.svg";

// import DecorationBlob1 from "../images/decorations/deco-blob-1.svg";
import DecorationBlob18 from "../images/decorations/deco-blob-18.svg";

import Divider1 from "../images/dividers/divider-5.svg";
import Divider2 from "../images/dividers/divider-3.svg";
import { UxDesignProcessJsonLd } from "../components/jsonld/UxDesignProcessJsonLd";

const DatoAsset = ({ src, alt, className, style, lazy = true }) => (
  <picture>
    <source
      type="image/webp"
      srcSet={`${src}?fm=webp&w=200 200w,
${src}?fm=webp&w=400 400w,
${src}?fm=webp&w=800 800w,
${src}?fm=webp&w=1200 1200w,
${src}?fm=webp&w=1436 1436w`}
      sizes="(max-width: 800px) 100vw, 800px"
    />
    <source
      srcSet={`${src}?w=200 200w,
${src}?w=400 400w,
${src}?w=800 800w,
${src}?w=1200 1200w,
${src}?w=1436 1436w`}
      sizes="(max-width: 800px) 100vw, 800px"
    />
    <img
      sizes="(max-width: 800px) 100vw, 800px"
      srcSet={`${src}?fm=webp&w=200 200w,
${src}?w=400 400w,
${src}?w=800 800w,
${src}?w=1200 1200w,
${src}?w=1436 1436w`}
      src={`${src}?w=1200`}
      alt={alt || ""}
      className={className || ""}
      style={style || {}}
      loading={lazy ? "lazy" : "eager"}
    />
  </picture>
);

const UXDesignProcessPage = ({ data, location: { pathname } }) => {
  // const ArticlesData = data.allDatoCmsArticle.edges;
  //
  // const [elemCtaOffsetTop, setElemCtaOffsetTop] = useState(0);
  // const [elemStickyOffsetTop, setElemStickyOffsetTop] = useState(0);


  const toggleAccordion = e => {
    const panel = e.target;
    const panelAccordionContent = panel.querySelector(`.accordion-content`);
    const panelIcon = panel.querySelector(".icon");

    if (panelAccordionContent.className.includes("collapse")) {
      panelAccordionContent.classList.remove("collapse");
      panelIcon.classList.add("icon-rotate");
    } else {
      panelAccordionContent.classList.add("collapse");
      panelIcon.classList.remove("icon-rotate");
    }
  };

  return (
    <Layout
      headerBgClass="bg-white pillar-page-header"
      meta={{ seo: data.datoCmsPage.seo, path: pathname }}
    >
      <UxDesignProcessJsonLd />

      <div className="pillar-page ux-process-page o-hidden">
        <section className="text-light bg-primary pb-0">
          <div className="decoration-wrapper">
            {/* <div className="decoration left top" style={{ transform: "rotate(-62.34deg)" }}>
              <DecorationBlob1 className="bg-primary" style={{ transform: "scale(5)" }} />
            </div> */}
          </div>

          {/* Intro - Hero */}
          <div className="container">
            <div className="row align-items-center min-vh-50">
              <div className="col-lg-6 lead">
                <h1 className="display-4">
                  Our UX Design Process & Deliverables
                </h1>
                <p>
                  Scroll down to read our UX design process and deliverables
                  guide. Find out how we do things and see why we&rsquo;re
                  paving the way in innovative and practical UX design.
                </p>
              </div>
              <div className="col-lg-6 d-flex align-items-center">
                <DatoAsset
                  className="min-vw-50 w-100"
                  src="https://www.datocms-assets.com/16499/1593276692-ux-design-process-design-agency.png"
                  alt="UX Design Process"
                  lazy={false}
                />
              </div>
            </div>
          </div>

          <div className="bgDivider2 divider">
            <Divider2 style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
          </div>

          {/* <SectionOurBook /> */}
        </section>

        <section className="pt-0 pb-0 mt-n6">
          <div className="container">
            <div className="row">
              <div className="mt-n6 article col-xl-7 col-lg-8 col-md-10 mx-auto lead">
                <blockquote className="text-light">
                  <p className="h3">
                    “The difference between ordinary and extraordinary is that
                    little extra.”
                  </p>
                  <span className="text-small text-muted">– Jimmy Johnson</span>
                </blockquote>

                <p>
                  As a UX design agency, our initial goal is to gain
                  understanding and knowledge. We go above and beyond to learn
                  everything that will help us develop a better design solution.
                </p>
                <p>But first, what is UX design and why is it important?</p>
                <p>
                  Briefly, UX design is the process used to design a product
                  that is useful, easy to use, and valuable. The{" "}
                  <a
                    target="_blank"
                    href="https://adamfard.com/blog/ux-designer-role"
                  >
                    purpose of UX design
                  </a>{" "}
                  is to enhance user satisfaction and ensure a seamless
                  interaction between the product and the user.
                </p>

                <p>
                  And why is it important? Well, UX design is important because
                  it puts the user first. Specifically, a positive user
                  experience encourages users to stay loyal to a particular
                  product or brand. Additionally, a rich user experience allows
                  a more precise definition of{" "}
                  <a
                    target="_blank"
                    href="https://adamfard.com/blog/customer-journey-templates"
                  >
                    customer journeys
                  </a>{" "}
                  that are conducive to business success.
                </p>

                <h2 className="h1">UX Process on Film </h2>
                <p>
                  If videos catch your fancy better than written content, then look no further than the video 
                  we've shot on the UX process. The video in question talks about the same points outlined here.
                </p>
                <iframe 
                  className="embedded-video"
                  width="560" height="315" 
                  src="https://www.youtube.com/embed/6apD7d7ERzI" 
                  title="YouTube video player" frameborder="0" 
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen>
                </iframe>
                
              </div>
            </div>
          </div>
        </section>

        {/* UX Design Stages */}
        <section className="section-chapter o-hidden mt-3">
          <div className="decoration-wrapper">
            <div className="decoration right" style={{ top: -320, right: -70 }}>
              <DecorationBlob18
                className="bg-primary-alt"
                style={{ transform: "scale(0.92)" }}
              />
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-6">
                <h2 className="display-4">UX Design Stages</h2>
                <p className="col-xl-7 col-lg-8 col-md-10 mx-auto lead">
                  <strong>
                    These processes and their sequence are subject to change
                    based on what you’ve already done and your needs.
                  </strong>
                </p>
              </div>
            </div>

            <div className="row pb-7 justify-content-center">
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#audit">
                  <div className="card card--common card--blog--vertical card-body text-center">
                    <DatoAsset
                      src="https://www.datocms-assets.com/16499/1591812679-audit.jpg"
                      alt="Chapter 1"
                    />
                    <p className="lead mb-0 mt-1">Phase 1</p>
                    <h4 className="mb-0 mt-1">Understand & Audit</h4>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#research">
                  <div className="card card--common card--blog--vertical card-body text-center">
                    <DatoAsset
                      src="https://www.datocms-assets.com/16499/1591812688-research.jpg"
                      alt="Chapter 2"
                    />
                    <p className="lead mb-0 mt-1">Phase 2</p>
                    <h4 className="mb-0 mt-1">Research</h4>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#UX-strategy">
                  <div className="card card--common card--blog--vertical card-body text-center">
                    <DatoAsset
                      src="https://www.datocms-assets.com/16499/1591812691-strategy.jpg"
                      alt="Chapter 3"
                    />
                    <p className="lead mb-0 mt-1">Phase 3</p>
                    <h4 className="mb-0 mt-1">UX Strategy</h4>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#UX-conception">
                  <div className="card card--common card--blog--vertical card-body text-center">
                    <DatoAsset
                      src="https://www.datocms-assets.com/16499/1591812683-conception.jpg"
                      alt="Chapter 4"
                    />
                    <p className="lead mb-0 mt-1">Phase 4</p>
                    <h4 className="mb-0 mt-1">UX Conception</h4>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#UI-design">
                  <div className="card card--common card-body text-center">
                    <DatoAsset
                      src="https://www.datocms-assets.com/16499/1591812784-ui.jpg"
                      alt="Chapter 5"
                    />
                    <p className="lead mb-0 mt-1">Phase 5</p>
                    <h4 className="mb-0 mt-1">Visual and UI Design</h4>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#QA-analysis">
                  <div className="card card--common card-body text-center">
                    <DatoAsset
                      src="https://www.datocms-assets.com/16499/1591812686-qa.jpg"
                      alt="Chapter 6"
                    />
                    <p className="lead mb-0 mt-1">Phase 6</p>
                    <h4 className="mb-0 mt-1">QA & Analysis</h4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Start Phases */}
        <div>
          {/* Phase 1 */}
          <WatchedPhaseSection
            isDivider={true}
            bgColor="bg-1"
            activePhase="Phases / Section 1"
            phaseId="audit"
            bgDivider2="bg-white"
            headerContent={() => (
              <div className="container pb-0 text-light">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 d-flex flex-column text-center">
                    <p>
                      <strong>Phase 1:</strong>
                    </p>
                    {/* <div>
                      <img
                        className="w-50 border-rounded"
                        src="https://www.datocms-assets.com/16499/1591812679-audit.jpg"
                        alt="Understand & Audit"
                      />
                    </div> */}
                    <h2 className="display-4">Understand & Audit</h2>
                  </div>
                  {/* <div className="col-12">
                    <img
                      className="w-100"
                      src="https://www.datocms-assets.com/16499/1591813829-ux-audit-tn.png"
                      alt="Understand & Audit"
                    />
                  </div> */}
                </div>
              </div>
            )}
            mainContent={() => (
              <div>
                <div className="container pt-2 text-light">
                  <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8 col-md-10">
                      <article className="article lead">
                        <p className="h4">
                          Goal: Review the product to learn more about business
                          goals, user needs, and technical limitations.
                        </p>
                        <p>
                          A rule of thumb of UX design is getting to know your
                          client.{" "}
                          <em>
                            Who are they? What do they do? Where are they going?
                            How are they connecting with users?
                          </em>{" "}
                          So it&rsquo;s no surprise that we like to kick things
                          off with a UX audit.
                        </p>
                        <p>
                          But what is a{" "}
                          <a
                            target="_blank"
                            href="https://adamfard.com/blog/ux-audit"
                          >
                            UX audit
                          </a>
                          ?
                        </p>

                        <p>
                          One definition of a UX audit is that it is a method used to pinpoint the
                          less-than-perfect areas of a digital product,
                          revealing which parts of a site or app are red flags.
                        </p>
                        <p>
                          During a UX audit, we use a range of methods, tools,
                          and metrics to identify areas where a product is
                          drifting away from
                          <a
                            target="_blank"
                            href="https://www.userfocus.co.uk/resources/guidelines.html"
                          >
                            {" "}
                            pre-established standards or goals
                          </a>
                          .
                        </p>
                        <p className="">
                          Typically, a UX audit includes activities
                          like usability testing, desk research, user interviews, etc.
                        </p>

                        <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1667236576-ux-audit.png"
                              alt="UX Audit"
                            />
                            {/* <figcaption>Different Types of Heatmaps</figcaption> */}
                          </figure>

                        
                      </article>
                    </div>
                  </div>
                </div>

                <div className="bgDivider2 divider">
                  <Divider2 style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <h3 className="h1">Business Goals and Requirements</h3>
                      </div>
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <p>
                            Before we start analyzing any digital product,
                            we first need to find out more about your business
                            goals and brand vision.
                          </p>
                          <p>
                            To do this, we gather and document information via
                            desk research or {" "}
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/stakeholder-interviews"
                            >
                              stakeholder interviews
                            </a>
                            . These activities result in team alignment on things vital to the product’s success.

                          </p>
                          <p className="pt-3">
                            Some example questions from our stakeholder
                            interviews:
                          </p>
                          <ul>
                            <li>
                              What are your short and long-term{" "}
                              <strong>business goals</strong>?
                            </li>
                            <li>
                              Who are your <strong>users</strong>?
                            </li>
                            <li>
                              What are some of your{" "}
                              <strong>company values</strong>?
                            </li>
                            <li>
                              What are some of your{" "}
                              <strong>product concerns</strong>?
                            </li>
                            <li>
                              Who are some of your{" "}
                              <strong>major competitors</strong>?
                            </li>
                            <li>
                              What are your{" "}
                              <strong>primary sales channels</strong>?
                            </li>
                            <li>
                              What is your{" "}
                              <strong>definition of product success</strong>?
                            </li>
                          </ul>
                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593769243-business-goals.png"
                              alt="Business Goals and Requirements"
                            />
                            <figcaption>
                              Part of a business goal and requirements gathering
                              document
                            </figcaption>
                          </figure>
                          <p>
                            One of our preferred ways to collect requirements
                            and understand your goals is conducting a discovery
                            workshop. This way, we’ll be able to rephrase
                            problems into opportunities, select a focus area,
                            generate ideas, and possible solutions.
                          </p>
                          
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white o-hidden">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <div className="col-xl-7 col-lg-8 col-md-10">
                            <h3 className="h1">Analytics Data</h3>
                          </div>

                          <p>
                            Once we have a clear idea of what your business
                            objectives are, we want to see how they are holding
                            up in reality. At this stage, we want to delve into
                            data to find out exactly where your product is on
                            the progress meter.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1592676449-heatmaps.png"
                              alt="Heatmap Analytic"
                            />
                            <figcaption>Different Types of Heatmaps</figcaption>
                          </figure>

                          <p>
                            Specifically, we want conclusive answers to
                            questions like:
                          </p>
                          <p className="font-weight-bold">
                            <em>
                              What are your users searching for? Where are they
                              coming from? At what point in the sales funnel are
                              they jumping ship?
                            </em>
                          </p>
                          <p>
                            We use a variety of{" "}
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/ux-tools"
                            >
                              UX analytic tools
                            </a>
                            , such as Google Analytics, Hotjar, Kissmetrics, and
                            Omniture, to turn data into valuable insights about:
                          </p>

                          <ul>
                            <li>User demographics</li>
                            <li>Conversion metrics</li>
                            <li>Sales data</li>
                            <li>Traffic flow and engagement</li>
                            <li>Customer care data</li>
                            <li>Heatmaps</li>
                            <li>Scrollmaps</li>
                            <li>Form analytics</li>
                            <li>Poll/form results</li>
                            <li>User testing results</li>
                          </ul>

                          <p>
                            All of this data is taken and used to create
                            personas and user journeys which will help us and
                            stakeholders better engage with and target users.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">Heuristic Evaluation</h3>

                          <p>
                            The last step of the audit phase is to{" "}
                            <a
                              target="_blank"
                              href="https://www.nngroup.com/articles/how-to-conduct-a-heuristic-evaluation/"
                            >
                              conduct a heuristic evaluation
                            </a>
                            . Heuristic evaluation is a method used to identify any
                            usability issues with the design interface. In
                            simple terms, it tests how user-friendly the user
                            interface is.
                          </p>

                          <p>
                            Unlike user-testing where users test the product, in
                            a heuristic evaluation, the product is evaluated by
                            usability experts. Though there are plenty of
                            criteria by which a digital product can be
                            evaluated, the majority of heuristic evaluations
                            utilize
                            <a
                              target="_blank"
                              href="https://www.nngroup.com/articles/ten-usability-heuristics/"
                            >
                              {" "}
                              Jacob Nielsen&rsquo;s 10 Usability Heuristics for
                              User Interface Design
                            </a>{" "}
                            or a variation of these heuristics adapted to the
                            project, platform or interface:
                          </p>

                          <ol>
                            <li>Visibility of system status.</li>
                            <li>Match between system and the real world.</li>
                            <li>User control and freedom.</li>
                            <li>Consistency and standards.</li>
                            <li>Error prevention.</li>
                            <li>Recognition rather than recall.</li>
                            <li>Flexibility and efficiency of use.</li>
                            <li>Aesthetic and minimalist design.</li>
                            <li>
                              Help users recognize, diagnose, and recover from
                              errors.
                            </li>
                            <li>Help and documentation.</li>
                          </ol>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1644183188-usability-heuristics.png"
                              alt="Heuristic Evaluation"
                            />
                            <figcaption>
                                10 usability heuristics
                            </figcaption>
                          </figure>

                          <p>
                            All issues that are uncovered during the audit phase
                            are documented. We compile and synthesize findings
                            for our clients, sort the list by impact and
                            severity, and make suggestions that will result in
                            the best ROI based on client budget and timeline.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1592675442-ux-audit-report.png"
                              alt="UX Audit Report"
                            />
                            <figcaption>
                              Example of a UX Audit Report
                            </figcaption>
                          </figure>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />

          {/* Phase 2 */}
          <WatchedPhaseSection
            isDivider={true}
            bgColor="bg-2"
            activePhase="Phases / Section 2"
            phaseId="research"
            headerContent={() => (
              <div className="container pb-0 text-light">
                <div className="row justify-content-center">
                  {/* <div className="col-4 mx-auto">
                    <img
                      src="https://www.datocms-assets.com/16499/1591812688-research.jpg"
                      alt="UX Research"
                    />
                  </div> */}
                  <div className="col-12 col-md-8 d-flex flex-column text-center">
                    <p>
                      <strong>Phase 2:</strong>
                    </p>
                    <h2 className="display-4">UX Research</h2>
                  </div>

                  {/* <div className="col-12">
                    https://www.datocms-assets.com/16499/1591812688-research.jpg
                    <img
                      src="https://www.datocms-assets.com/16499/1591813870-ux-research-tn.png"
                      alt="UX Research"
                    />
                  </div> */}
                </div>
              </div>
            )}
            mainContent={() => (
              <div>
                <div className="">
                  <div className="container pt-2 text-light">
                    <div className="row justify-content-center">
                      {/* <div className="col-xl-5 col-lg-4 col-md-2">
                          <img
                            src="https://www.datocms-assets.com/16499/1591813870-ux-research-tn.png"
                            alt="UX Research"
                          />
                        </div> */}
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <p className="h4">
                            Goal: Dive deep into the market and most importantly
                            – the mind of our users to eliminate uncertainty and
                            guesswork.
                          </p>
                          <p>
                            With the business objectives in mind, the next step
                            of our design process is to conduct research. As
                            you&rsquo;re well aware, research is an integral
                            part of any design undertaking. In fact, the
                            research phase is the heart and soul of a project.
                          </p>

                          <p>
                            By implementing various
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/benefits-of-ux-research"
                            >
                              {" "}
                              UX research methods
                            </a>
                            , like competitive analysis, user research,{" "}
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/contextual-inquiry"
                            >
                              contextual inquiry
                            </a>
                            , we are able to gain valuable insights into
                            users&rsquo; needs, behavior, and motivations.
                          </p>

                          <p>
                            It&rsquo;s important to note that the information
                            revealed during this stage will determine the entire
                            flow of a project. It&rsquo;s kind of like setting
                            the foundations to a building. This is why we take
                            extra care to ensure our research methodologies run
                            smoothly so that your building stays standing.
                          </p>
                          <p>
                            Let&rsquo;s look at all the stages covered in the
                            research phase:
                          </p>

                          <ul className="font-weight-bold">
                            <li><a href="https://adamfard.com/blog/competitive-analysis">Competitive Analysis</a></li>
                            <li>User Research</li>
                            <li><a href="https://adamfard.com/blog/ux-research-process">UX/UI Research</a></li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bgDivider2 divider">
                  <Divider2 style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">Competitive Analysis</h3>
                          <p>
                            The saying goes,{" "}
                            <em>
                              &ldquo;Keep your friends close and your enemies
                              closer&rdquo;
                            </em>
                            . As far as digital products are concerned, this
                            pretty much hits the nail on the head.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1592675431-competitive-analysis.png"
                              alt="Competitive Analysis"
                            />
                            <figcaption>
                              Example of a competitive analysis
                            </figcaption>
                          </figure>

                          <p>
                            In order for a product to succeed, it&rsquo;s
                            essential that we keep tabs on competitors. This
                            means monitoring what your competitors are doing and
                            how successful they are in doing it.
                          </p>
                          <p>
                            And it&rsquo;s our job to help you
                            <a
                              target="_blank"
                              href="https://www.oreilly.com/library/view/ux-strategy/9781449372972/ch04.html"
                            >
                              {" "}
                              analyze your competitors
                            </a>{" "}
                            and their products or services.
                          </p>
                          <p>
                            There are two key principles of a successful
                            competitive analysis:
                          </p>

                          <ol>
                            <li> Knowing what information to look for.</li>
                            <li>
                              {" "}
                              Synthesizing that information into actionable
                              insight.
                            </li>
                          </ol>
                          <p>
                            It&rsquo;s our aim to adhere to these principles so
                            that you have full access to the benefits of
                            competitive analysis:
                          </p>
                          <ul>
                            <li>Identify ways to solve usability problems.</li>
                            <li>
                              Understand where your product stands in the
                              market.
                            </li>
                            <li>Recognize market gaps that can be utilized.</li>
                            <li>
                              Acknowledge competitors&rsquo; weaknesses and
                              strengths.
                            </li>
                            <li>Validate your design decisions.</li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">User Research Takeaways</h3>
                          <p>
                            At this point of our UX design process, we want to
                            connect with users to understand their decision-making process. What drives them to continue using
                            your product &ndash; or stop using it? What do they
                            consider a deal breaker when interacting with your
                            product &ndash; or a similar one for that matter? Is
                            there a pattern in user behavior that is worth
                            looking into?&nbsp;
                          </p>
                          <p>
                            This is what we set out to find out and communicate
                            our findings to you. User research lets us learn
                            more about your users and their behavior. To carry
                            out user research we employ diverse user research
                            techniques. Here you find two examples: surveys and
                            personas.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">User Surveys</h3>
                          <p>
                            Surveys are user research tools used to collect a
                            large set of data about your users. Though they are
                            mainly used to accumulate <a href="https://adamfard.com/blog/quantitative-testing-ux">quantitative data</a>, surveys
                            can also be used to validate qualitative findings.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593769240-user-survey.png"
                              alt="User Survey"
                            />
                            <figcaption>Example of a user survey</figcaption>
                          </figure>

                          <p>
                            What we find great about surveys is that they can
                            generate a lot of feedback quickly and efficiently.
                            The secret to
                            <a
                              target="_blank"
                              href="https://www.invisionapp.com/inside-design/how-to-create-a-survey/"
                            >
                              {" "}
                              creating a successful survey
                            </a>
                            , however, is knowing <em>what</em> questions to
                            ask, <em>how</em> to formulate them, and{" "}
                            <em>who</em> to ask them to.
                          </p>
                          <p>
                            In our experience, the most effective surveys are
                            short, topic-specific, and have a combination of
                            closed and open-ended questions. For this reason, we
                            make sure we utilize both closed and open-ended
                            questions so that we are able to gather enough
                            context, while keeping participants interested.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">User Personas</h3>
                          <p>
                            <a href="https://adamfard.com/blog/ux-personas">User personas</a> are fictional characters that are
                            created using research material and represent the
                            different types of users that interact with your
                            product, service, or brand. Generally, the persona
                            is presented as a 1-2 page description of an
                            archetypal user.
                          </p>
                          <p>
                            User personas typically contain the following bits
                            of information:
                          </p>
                          <ul>
                            <li>Demographics</li>
                            <li>Background.</li>
                            <li>Needs.</li>
                            <li>Goals.</li>
                            <li>Frustrations.</li>
                            <li>Motivation.</li>
                          </ul>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593436289-ux-persona.jpg"
                              alt="User Persona"
                            />
                            <figcaption>User Persona</figcaption>
                          </figure>

                          <p>
                            The purpose of user personas is to relay how users
                            experience the same environment differently.
                            Additionally, they&rsquo;re a vital tool to the UX
                            design process and provide a deeper understanding of
                            the target audience and guide the ideation process.
                          </p>
                          <p>
                            We utilize personas as an effective user research
                            tool to help pinpoint which groups of users you
                            should target and how to approach these user types.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">User Story / Jobs-to-be-done</h3>

                          <p>
                            A user story is a brief &ndash;{" "}
                            <em>yet specific</em> - scenario stating what the
                            user wants to accomplish by using the product. It
                            describes the most basic goal of a user, such as
                            signing up for a new account, finding the checkout,
                            contacting support, etc
                          </p>
                          <p>
                            Because they are short and specific, it takes a
                            number of user stories in order to align the design
                            with users&rsquo; goals. Nevertheless, they are an
                            effective way to organize and prioritize design
                            tasks.
                          </p>
                          <p>
                            Implementing user stories during our design process
                            helps us accomplish three things:
                          </p>
                          <ul>
                            <li>User stories ensure design is user-focused.</li>
                            <li>
                              User stories clarify design process to avoid
                              feature creeps.
                            </li>
                            <li>
                              User stories coordinate various stages of the
                              design process.
                            </li>
                          </ul>
                          <p>
                            We share our user stories through a collaborative
                            spreadsheet where the client and various
                            stakeholders can add any stories they feel are
                            missing.
                          </p>
                          <p>
                            We also utilize job stories to help us define user
                            tasks in product design. In short, a job story
                            focuses on the &lsquo;jobs that need to be
                            done&rsquo; with an emphasis on context, causality,
                            and motivation, as opposed to assumptions and
                            personas.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593769245-jobs-to-be-done.png"
                              alt="Jobs-to-be-done"
                            />
                            <figcaption>Example of jobs-to-be-done</figcaption>
                          </figure>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">Use Case</h3>

                          <p>
                            A use case is a written account explaining how users
                            will perform different tasks. Think along the lines
                            of a design instruction manual. Each use case is
                            written as a sequence of steps depicting how the
                            system should behave when triggered by a user.
                          </p>
                          <p>
                            The
                            <a
                              target="_blank"
                              href="http://tynerblain.com/blog/2006/06/23/the-8-goals-of-use-cases/"
                            >
                              {" "}
                              goal of the use case
                            </a>{" "}
                            is to define the correct sequence of actions that
                            users need to complete in order to accomplish the
                            task at hand. A use case is made up of the following
                            elements:
                          </p>
                          <ul>
                            <li>
                              <strong>Preconditions.</strong> Conditions that
                              have to be met beforehand.
                            </li>
                            <li>
                              <strong>Actor.</strong> Anyone who is using the
                              system.
                            </li>
                            <li>
                              <strong>Trigger.</strong> An event that causes an
                              action to take place.
                            </li>
                            <li>
                              <strong>Standard flow.</strong> The typical
                              pathway a user takes to achieve a goal.
                            </li>
                            <li>
                              <strong>Alternate flow.</strong> A secondary
                              pathway in the event a problem occurs.
                            </li>
                            <li>
                              <strong>Stakeholder.</strong> Anyone with a vested
                              interest in the behavior of the system.
                            </li>
                          </ul>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593769247-use-case.jpg"
                              alt="Use Case"
                            />
                            <figcaption>Use Case</figcaption>
                          </figure>

                          <p>
                            This part of the research phase is particularly
                            important as it explains to stakeholders how the
                            system will work and allows room for them to
                            brainstorm additional functionalities or flag
                            possible problems.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">Experience Map</h3>

                          <p>
                            An experience map is a design tool used to observe
                            the experience a user has with a product or service.
                            It is the visualization of an entire end-to-end
                            experience that a user goes through in order to
                            accomplish a goal.
                          </p>
                          <p>
                            By
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/customer-journey-templates"
                            >
                              {" "}
                              creating an experience map
                            </a>
                            , stakeholders become more adept to recognize
                            strategic opportunities, face user pain points, and
                            optimize conversion funnels.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593435939-experience-mapping.jpg"
                              alt="Experience Map"
                            />
                            <figcaption>Experience Map</figcaption>
                          </figure>

                          <p>
                            Mapping the user experience lets us forecast any
                            friction in the UX before it&rsquo;s too late. By
                            doing this our clients can organize and prioritize
                            their activities related to user experience.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">Storyboards</h3>

                          <p>
                            Stories are one of the most effective delivery tools
                            for information. And using images to map the user
                            experience is an even more powerful way to capture,
                            relate, and explore experiences in the design
                            process. This is why we integrate storyboards as
                            part of our research phase.
                          </p>
                          <p></p>
                          <p>
                            A <a href="https://adamfard.com/blog/storyboarding">storyboard</a> is a chronological sequence of images
                            that visually narrate the user&rsquo;s
                            &lsquo;story&rsquo;.
                          </p>
                          <p>There are three main storyboard elements:</p>
                          <ol>
                            <li> Scenario</li>
                            <li> Visuals</li>
                            <li> Captions</li>
                          </ol>
                          <p>
                            In our experience, having a storyboard provides
                            additional context to both our design teams and
                            stakeholders.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593769233-ux-storyboard.jpg"
                              alt="Storyboard"
                            />
                            <figcaption>UX Storyboard</figcaption>
                          </figure>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">User Scenarios</h3>

                          <p>
                            A user scenario is an extension of a user story. Its
                            aim is to describe a fictitious story of a user
                            accomplishing a goal by interacting with a product.
                            Focusing on the user&rsquo;s motivations, a user
                            scenario documents the process by which the user
                            might use a design in their persona&rsquo;s context.
                          </p>
                          <p>
                            User scenarios are ideal research tools to predict
                            the different ways a user could use the product. We
                            like to implement user scenarios during the research
                            phase of our design process to generate design
                            ideas. They also help determine the most important
                            areas to test during usability testing later on.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593769238-user-scenario.png"
                              alt="User Scenario"
                            />
                            <figcaption>User Scenario</figcaption>
                          </figure>

                          <p>
                            We provide a key takeaways document that lists major
                            research findings. Apart from this document we
                            provide all source materials used during the
                            research phase, such as interview scripts, research
                            templates, recordings, notes, and a competitive
                            analysis matrix. These documents help get
                            stakeholder buy-in regarding user goals and design
                            features.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />

          {/* Phase 3 */}
          <WatchedPhaseSection
            isDivider={true}
            bgColor="bg-3"
            activePhase="Phases / Section 3"
            phaseId="UX-strategy"
            headerContent={() => (
              <div className="container pb-0 text-light">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 d-flex flex-column text-center">
                    <p>
                      <strong>Phase 3:</strong>
                    </p>
                    <h2 className="display-4">UX Strategy</h2>
                  </div>

                  {/* <div className="col-12">
                    <img
                      src="https://www.datocms-assets.com/16499/1591813962-ux-strategy-tn.png"
                      alt="UX Strategy"
                    />
                  </div> */}
                </div>
              </div>
            )}
            mainContent={() => (
              <div>
                <div className="container pt-2 text-light">
                  <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8 col-md-10">
                      <article className="article lead">
                        <p className="h4">
                          Goal: Draft a foolproof UX strategy by combining
                          research data.
                        </p>

                        <p>
                          at this stage, of our UX design process, we combine the
                          data collected from the previous phases and define a
                          set of KPIs and a clear UX strategy.
                        </p>
                      </article>
                    </div>
                  </div>
                </div>

                <div className="bgDivider2 divider">
                  <Divider2 style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                </div>

                <div className="bg-white">
                  <div className="container pt-4">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <p>
                            A{" "}
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/ux-strategy"
                            >
                              UX strategy
                            </a>{" "}
                            is the plan for the digital product. It should
                            articulate the brand, offer guiding principles, and
                            support the long-term vision of the business. It is
                            different from a customer experience strategy which
                            focuses on different aspects of the brand, such as
                            customer service, sales processes, brand
                            consistency, pricing, and product delivery.
                          </p>

                          <p>
                            A well-defined UX strategy is made up of four
                            components:
                          </p>

                          <ul className="font-weight-bold">
                            <li>Business strategy.</li>
                            <li>Value innovation.</li>
                            <li>Validated user research.</li>
                            <li>Flawless user experience.</li>
                          </ul>

                          <DatoAsset
                            src="https://www.datocms-assets.com/16499/1591814930-ux-strategy.jpg"
                            alt="UX Strategy"
                          />

                          <p>
                            The UX strategy has one main purpose &ndash; to solve
                            specific problems. For this reason, we take extra
                            care to ensure that the business vision, user needs,
                            and technical capabilities are aligned.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />

          {/* Phase 4 */}
          <WatchedPhaseSection
            isDivider={true}
            bgColor="bg-4"
            activePhase="Phases / Section 4"
            phaseId="UX-conception"
            headerContent={() => (
              <div className="container pb-0 text-light">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 d-flex flex-column text-center">
                    <p>
                      <strong>Phase 4:</strong>
                    </p>
                    <h2 className="display-4">User Experience Design</h2>
                  </div>
                  {/* <div className="col-12">
                    <img
                      src="https://www.datocms-assets.com/16499/1591813998-ux-design-tn.png"
                      alt="User Experience Design"
                    />
                  </div> */}
                </div>
              </div>
            )}
            mainContent={() => (
              <div>
                <div className="">
                  <div className="container pt-2 text-light">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <p className="h4">
                            Goal: Define a design concept to align interaction
                            patterns, user paths, and interfaces.
                          </p>
                          <p>
                            at this stage, of our design process we use a variety
                            of methodologies to define a concept that will shape
                            the entire design elements: interactions, the
                            relationship between elements, tone of voice and
                            copy, transitions, animations, etc. Basically,
                            everything that is necessary to align product vision
                            to reality. Here is how we do it:
                          </p>
                          <ul className="font-weight-bold">
                            <li>Information Architecture</li>
                            <li>User Flow</li>
                            <li>Low-fi Wireframes</li>
                            <li>Hi-fi Wireframes</li>
                            <li>Interactive Prototype</li>
                            <li>User Testing Report</li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bgDivider2 divider">
                  <Divider2 style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">Information Architecture</h3>

                          <p>
                            We take all the collected data from the research
                            phase to build an{" "}
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/information-architecture"
                            >
                              information architecture
                            </a>{" "}
                            for the product. As the name suggests, information
                            architecture is all about organizing information in
                            a clear and logical way.
                          </p>
                          <p>
                            We generally present the IA using a mind map that
                            gives a bird&rsquo;s eye view of the system and
                            shows the relationship between parts, thus helping
                            users navigate complex sets of information. In our
                            experience, this mind mapping process is
                            particularly effective when performed in
                            collaboration with stakeholders.
                          </p>
                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1592731092-information-architecture.jpg"
                              alt="User Flow"
                            />
                            <figcaption>
                              Part of an information architecture
                            </figcaption>
                          </figure>
                          <p>
                            By visualizing the overall project structure, it is
                            easier to understand the scope of the project and
                            define feature priorities. The aim here is to make
                            sure users will find the product more usable and
                            intuitive.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">User Flow</h3>
                          <p>
                            User flows are a visualization of the steps a user
                            needs to take in order to complete a specific task.
                            The aim of 
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/user-flows"
                            >
                              {" "}
                              user flows
                            </a>{" "}
                            is to show the pages, logic, and action necessary to
                            reach the goal. At the same time, it provides
                            developers with a description of all possible user
                            flows, alternate triggers, and errors.
                          </p>
                          <p>
                            We provide stakeholders with a user flow document
                            created in a design-specification format that merges
                            screen layout designs with flowchart-like
                            representations of user interactions within the
                            system.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1592675415-user-flow.png"
                              alt="User Flow"
                            />
                            <figcaption>Example of a user flow</figcaption>
                          </figure>

                          <p>
                            As the design progresses, we continue to update this
                            document over the course of a project&rsquo;s
                            lifespan. This allows us to iterate on the user
                            flows more efficiently and identify any problems
                            early on in the design process.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">Low-Fidelity Wireframes</h3>

                          <p>
                            Low fidelity wireframes are the most basic visual
                            representations of the design layout and features of
                            the product. They are typically static and their
                            role is to map out the skeleton of the interface its
                            screens, user flows, and information architecture.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593191618-low-fi-wireframes.jpg"
                              alt="Low-Fidelity Wireframes"
                            />
                            <figcaption>Low-Fidelity Wireframes</figcaption>
                          </figure>

                          <p>
                            Low-fi{" "}
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/wireframes"
                            >
                              wireframes
                            </a>{" "}
                            allow us to communicate visual ideas to stakeholders
                            so that the design concept is easier to understand
                            and we can quickly act on feedback from stakeholders
                            and users. This lets us make changes before further
                            design and development take place.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">High-Fidelity Wireframes</h3>

                          <p>
                            High fidelity wireframes are much more realistic
                            depictions of the final design than low fidelity
                            wireframes. They are built in the advanced stages of
                            the UX design process and are usually interactive,
                            mimicking authentic interface interaction.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593192543-hi-fi-wireframes.jpg"
                              alt="High-Fidelity Wireframe"
                            />
                            <figcaption>High-Fidelity Wireframe</figcaption>
                          </figure>

                          <p>
                            Final iterations are made at this stage, with a
                            focus on refining layout, graphics, and spacing.
                            Based on our experience, high fidelity wireframes
                            are useful to showcase a more accurate depiction of
                            what the final product will look and feel like
                            before it is finalized.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">Interactive Prototype</h3>
                          <p>
                            To give our stakeholders an even more realistic
                            visualization of the product in progress, we create
                            interactive prototypes as part of the wireframing
                            and design processes. We do this using a variety of
                            tools, depending on whether we want to test simpler
                            or more complex functionalities.
                          </p>
                          <p>
                            An{" "}
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/prototyping"
                            >
                              interactive prototype
                            </a>{" "}
                            is beneficial to the overall design process in a
                            number of ways, such as:
                          </p>
                          <ul>
                            <li>Brings the design to life.</li>
                            <li>Generates feedback from proper context.</li>
                            <li>Reduces overall development time.</li>
                            <li>Acts as a reference tool for developers.</li>
                            <li>
                              Confirms shared vision among users and
                              stakeholders.
                            </li>
                            <li>Validates assumptions and tests hypotheses.</li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">User Testing Report</h3>
                          <p>
                            We find it constructive to take a step back from
                            time to time and validate our assumptions and design
                            decisions by conducting a series of user tests using
                            an interactive prototype.
                          </p>
                          <p>
                            After the{" "}
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/usability-testing"
                            >
                              usability testing
                            </a>{" "}
                            is complete, we develop a user testing report which
                            contains a summary of our findings, as well as
                            suggestions for improving the design. This document
                            provides insight into users&rsquo; expectations and
                            is important in evaluating a product&rsquo;s
                            success.
                          </p>
                          <p>
                            The user testing report also provides context to the
                            testing, as it outlines testing goals, test setup,
                            and user demographics.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />

          {/* Phase 5 */}
          <WatchedPhaseSection
            isDivider={true}
            bgColor="bg-5"
            activePhase="Phases / Section 5"
            phaseId="UI-design"
            headerContent={() => (
              <div className="container pb-0 text-light">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 d-flex flex-column text-center">
                    <p>
                      <strong>Phase 5:</strong>
                    </p>
                    {/* <div className="col-4">
                      <img
                        src="https://www.datocms-assets.com/16499/1591814062-visual-design-tn.png"
                        alt="Visual and UI Design"
                      />
                    </div> */}
                    <h2 className="display-4">Visual and UI Design</h2>
                  </div>
                </div>
              </div>
            )}
            mainContent={() => (
              <div>
                <div className="container pt-2 text-light">
                  <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8 col-md-10">
                      <article className="article lead">
                        <p className="h4">
                          Goal: Develop and standardize the graphical interface
                          of the product.
                        </p>

                        <p>
                          at this stage, of our design process we begin to
                          develop the graphical interface of the product. Here
                          we apply everything we have learned from users and
                          stakeholders, and proceed with exploring ways to
                          design interfaces that are consistent and scalable.
                        </p>

                        <p>
                          All decisions regarding the visual design of the user
                          interface are documented in a UI style guide. A UI
                          style guide standardizes the grid system, layout,
                          color palette, typography, iconography, logos, and
                          imagery.
                        </p>

                        <p>
                          Here are all the stages we cover in the visual and UI
                          design phase:
                        </p>

                        <ul className="font-weight-bold">
                          <li>Moodboards</li>
                          <li>Visual Design Mockups</li>
                          <li>UI Animations</li>
                          <li>Design System &amp; UI Library</li>
                          <li>Design Specification For Developers</li>
                        </ul>
                      </article>
                    </div>
                  </div>
                </div>

                <div className="bgDivider2 divider">
                  <Divider2 style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">Moodboards</h3>
                          <p>
                            Once we have a solid understanding of how the user
                            flow works, it&rsquo;s time to think about how we
                            want the product to look and feel like. A great way
                            to start with that is by compiling a moodboard
                            which is a diverse compilation of design inspiration
                            to help us lay out our options in front of us. Based
                            on these options, we come up with the very feel
                            that&rsquo;s consistent with the brand, practical
                            and delightful.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1592675427-moodboard.png"
                              alt="Moodboard"
                            />
                            <figcaption>Moodboard</figcaption>
                          </figure>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">Visual Design Mockups</h3>

                          <p>
                            Mockups are a visual depiction of how the user
                            interface will look like. They can be low or high
                            fidelity, static or interactive, single or
                            multi-page. We create our visual style based on
                            branding guidelines provided by stakeholders or come
                            up with a style from scratch.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593193344-ui-design.png"
                              alt="Responsive UI Design"
                            />
                            <figcaption>Responsive UI design</figcaption>
                          </figure>

                          <p>
                            The great thing about mockups is that they allow us
                            to experiment with color schemes, typography, and
                            styles to see what looks best. Also, they permit us
                            to make changes to the design quickly and
                            efficiently.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593190294-mockups.jpg"
                              alt="Example of a mockup"
                            />
                            <figcaption>Example of a mockup</figcaption>
                          </figure>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">UI Animations</h3>

                          <p>
                            We also deliver short animations as part of our UX
                            design in order to enhance the overall presentation
                            and interactivity of a product. UI animations show
                            the main interactions, transitions, and flows within
                            the system.
                          </p>

                          <figure>
                            <div className="d-flex large-img ">
                              <div className="col-6">
                                <DatoAsset
                                  src="https://www.datocms-assets.com/16499/1591823236-previewwatchos.gif"
                                  alt="UI Animation"
                                  className="mb-0"
                                />
                              </div>

                              <div className="col-6">
                                <DatoAsset
                                  src="https://www.datocms-assets.com/16499/1591825443-weather-app-ui-animation.gif"
                                  alt="UI Animation"
                                  className="mb-0"
                                />
                              </div>
                            </div>

                            <figcaption>Examples of UI Animations</figcaption>
                          </figure>

                          <p>
                            When used in the right context, UI animations make
                            the design stand out and steer users towards their
                            goal. Not only that, but such elements as animations
                            and illustrations help delight users which boosts
                            retention and general enjoyability of the product.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">Design System & UI Library</h3>
                          <p>
                            A{" "}
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/design-libraries"
                            >
                              UI design system
                            </a>{" "}
                            is a collection of iterative components and
                            guidelines that explain how these components work.
                            Or more simply, it&rsquo;s a set of standards for
                            design and code that reinforce design consistency.
                          </p>
                          <p>
                            A design system features the following components:
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1592675419-design-system.png"
                              alt="Design system"
                            />
                            <figcaption>Part of a Design System</figcaption>
                          </figure>

                          <ul>
                            <li>
                              <strong>Style and design guidelines</strong> -
                              icons, typography, colors, spacing, and
                              illustrations.
                            </li>
                            <li>
                              <strong>UI libraries </strong>- form design
                              elements, images, navigation, and overlays.
                            </li>
                            <li>
                              <strong>Content style guide</strong> - voice,
                              tone, language, principles and objectives.
                            </li>
                          </ul>

                          <p>
                            Having a design system document is a powerful
                            toolkit for our clients&rsquo; internal design
                            teams. It enables them to maintain consistency and
                            scalability when building new pages and adding new
                            functionalities.
                          </p>
                          <p>
                            One methodology used to develop design systems is
                            atomic design. This methodology is made of five
                            stages that work together to create interface design
                            systems in a more hierarchical manner. The five
                            stages of atomic design are:
                          </p>

                          <ol>
                            <li>
                              <strong>Atoms.</strong> Basic elements such as
                              form labels, inputs and buttons.
                            </li>
                            <li>
                              <strong>Molecules.</strong> Simple groups of
                              design elements that function together as a unit.
                            </li>
                            <li>
                              <strong>Organisms.</strong> Relatively complex
                              components that form distinct sections of an
                              interface.
                            </li>
                            <li>
                              <strong>Templates.</strong> Groups of organisms
                              that function together and demonstrate the
                              page&rsquo;s underlying content structure.
                            </li>
                            <li>
                              <strong>Pages. </strong>The highest level of
                              fidelity that shows what the interface will look
                              like with real representative content in place.
                            </li>
                          </ol>

                          <p>
                            One of the greatest advantages of atomic design is
                            that it allows a more critical approach to the UX
                            design process and lets us look at each component on
                            its own <em>and</em> as a part of a larger whole. In
                            turn, this encourages a more consistent and scalable
                            design.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">
                            Design Specification for Developers
                          </h3>
                          <p>
                            Design specifications are developed at the end of
                            the design phase and are intended to be used by
                            developers. These documents communicate details
                            about the UI &ndash;{" "}
                            <em>
                              such as colors, typography, and measurements
                            </em>{" "}
                            &ndash; and information about the product &ndash;{" "}
                            <em>
                              such as user flow, behavior, and functionality.{" "}
                            </em>
                            Developers use this document to build the product to
                            the designer&rsquo;s specifications.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1592675433-developer-handoff.png"
                              alt="Developer handoff"
                            />
                            <figcaption>
                              Example of a static handoff document
                            </figcaption>
                          </figure>

                          <p>
                            In our experience, we find that
                            <a
                              target="_blank"
                              href="https://theblog.adobe.com/design-specifications-speeding-design-development-workflow-improving-productivity/"
                            >
                              {" "}
                              design specifications for developers
                            </a>{" "}
                            are crucial to the success of the final product.
                            Though various aesthetic choices &ndash;{" "}
                            <em>like fonts</em> &ndash; might not seem like a
                            big deal to developers, the design specs explain why
                            this design choice was made. Essentially, it makes
                            sure everyone is on the same page and developers
                            correctly implement the design as envisioned.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1592751767-specs-figma.jpg"
                              alt="Developer handoff - using Figma"
                            />
                            <figcaption>
                              Example of a dynamic handoff using Figma
                            </figcaption>
                          </figure>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>
              </div>
            )}
          />

          {/* Phase 6 */}
          <WatchedPhaseSection
            isDivider={true}
            bgColor="bg-6"
            activePhase="Phases / Section 6"
            phaseId="QA-analysis"
            headerContent={() => (
              <div className="container pb-0 text-light">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 d-flex flex-column text-center">
                    <p>
                      <strong>Phase 6:</strong>
                    </p>
                    <h2 className="display-4">
                      QA, After Launch & Implementation
                    </h2>
                  </div>
                  {/* <div className="col-12">
                    <img
                      src="https://www.datocms-assets.com/16499/1591814101-qa-after-implementation.png"
                      alt="QA, After Launch & Implementatio"
                    />
                  </div> */}
                </div>
              </div>
            )}
            mainContent={() => (
              <div>
                <div className="container pt-2 text-light">
                  <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8 col-md-10">
                      <article className="article lead">
                        <p className="h4">
                          Goal: Find out if the product is producing the desired
                          result.
                        </p>
                        <p>
                          Once the product is launched it&rsquo;s time to
                          measure the results.{" "}
                          <em>
                            Have the desired KPIs been achieved? Have metrics
                            shifted? Are users interacting with the product as
                            intended and is the product producing the desired
                            result?
                          </em>{" "}
                          This is what we seek to find out at this stage.
                        </p>
                        <p>
                          To find this out, we provide clients with a correction
                          document for developers and a usage analytics report:
                        </p>
                        <ul className="font-weight-bold">
                          <li>
                            UI Polishing &amp; Correction Document For
                            Developers
                          </li>
                          <li>Usage Analytics Reports</li>
                        </ul>
                      </article>
                    </div>
                  </div>
                </div>

                <div className="bgDivider2 divider">
                  <Divider2 style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">
                            UI Polishing & Correction Document for Developers
                          </h3>
                          <p>
                            At some point after the implementation of a design,
                            it&rsquo;s very likely that some changes will need
                            to be made. Usually, these are minor corrections
                            that can have a great impact on the design and user
                            experience.
                          </p>
                          <p>
                            We go over the user interface again to spot any
                            inconsistencies or design flaws and take notes. When
                            we{" "}
                            <a
                              target="_blank"
                              href="https://adamfard.com/blog/design-validation"
                            >
                              validate the design
                            </a>
                            , we craft a correction document for developers.
                            This document depicts specific parts of the design
                            that need to be corrected with an explanation for
                            each one.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h3 className="h1">Usage Analytics Report</h3>
                          <p>
                            A usage analytics report contains insights into how
                            to improve the design and enhance the user
                            experience. But it&rsquo;s not just data and
                            visuals. The report contains explanations and
                            recommendations on where design improvements can be
                            made and why.
                          </p>

                          <figure>
                            <DatoAsset
                              className="large-img"
                              src="https://www.datocms-assets.com/16499/1593190292-usage-analytics.jpg"
                              alt="Usage analytics"
                            />
                            <figcaption>
                              Usage analytics - Behavior Flow
                            </figcaption>
                          </figure>

                          <p>
                            We use a variety of tools to collect new usage data
                            and feedback from users. Our aim is to provide you
                            with a full overview of interactions. Specifically,
                            which features are being used and by whom, how much
                            time users are spending on separate pages, and what
                            trends can be observed in user behavior.
                          </p>
                          <p>
                            A usage analytics report is also a useful record to
                            see the impact the design has had on the product
                            after developers have acted according to the
                            correction document.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-primary-3">
                  <div className="divider">
                    <Divider1
                      style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }}
                    />
                  </div>

                  <div className="container text-light">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h2 className="display-4">Bottom line</h2>
                          <p>
                            We&rsquo;re not afraid to go the extra mile for our
                            clients and deliver a better design that guarantees
                            a seamless user experience. Get a real solution to a
                            real problem. We&rsquo;ve got your back every step
                            of the way
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>

                  <div className="bgDivider2 divider">
                    <Divider2
                      style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }}
                    />
                  </div>
                </div>
              </div>
            )}
          />

          <section className="pt-0">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-10 col-lg-8 d-flex flex-column text-center">
                  <article
                    id="article-content"
                    className="article article-blog"
                    style={{ zIndex: 9993 }}
                  >
                    <p>
                      <Helmet>
                        <script type="application/ld+json">{`
                        {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                          "@type": "Question",
                          "name": "Is the UX process the same for every client?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Although UX process principles are universal, it's subject to change based on your goals, timeframe and other resources."
                          }
                        },
                        {
                          "@type": "Question",
                          "name": "How long does UX process take from start to finish?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Short answer - it depends. Long answer - it depends on the complexity of your product, your goals, the stage of the product development. Feel free to book a call with us, so we can give you a more specific estimate."
                          }
                        },
                        {
                          "@type": "Question",
                          "name": "Is every step of the design process outlined here necessary?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "No. For example, there’s no need for an audit of an app that hasn’t been designed yet. The steps we take depend on our goals and what has already been done. However, the sequence of design stages is always the same."
                          }
                        },
                        {
                          "@type": "Question",
                          "name": "Is your design process compatible with lean and agile methodologies?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Yes. in fact, the process outlined here constitutes a single iteration."
                          }
                        }]
                        }
                      `}</script>
                      </Helmet>
                    </p>
                    <h3 className="text-left">FAQ</h3>
                    <div
                      onClick={toggleAccordion}
                      style={{ cursor: 'pointer' }}
                      id="panel-1"
                      className="card card-sm card-body card--no-hover text-left"
                    >
                      <div
                        style={{ pointerEvents: 'none' }}
                        className="accordion-panel-title"
                        aria-controls="panel-1"
                      >
                        <p aria-controls="panel-1">
                          <strong aria-controls="panel-1">
                            {" "}
                            Is the UX process the same for every client?{" "}
                          </strong>
                        </p>
                        <DatoAsset
                          className="icon"
                          src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                          aria-controls="panel-1"
                        />
                      </div>
                      <div style={{ pointerEvents: 'none' }}  className="accordion-content collapse">
                        <div className="pt-3">
                          <p>
                            Although UX process principles are universal, it's
                            subject to change based on your goals, timeframe and
                            other resources.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={toggleAccordion}
                      style={{ cursor: 'pointer' }}
                      id="panel-2"
                      className="card card-sm card-body card--no-hover text-left"
                    >
                      <div
                        style={{ pointerEvents: 'none' }}
                        className="accordion-panel-title"
                        aria-controls="panel-2"
                      >
                        <p aria-controls="panel-2">
                          <strong aria-controls="panel-2">
                            {" "}
                            How long does UX process take from start to finish?{" "}
                          </strong>
                        </p>
                        <DatoAsset
                          className="icon"
                          src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                          aria-controls="panel-2"
                        />
                      </div>
                      <div style={{ pointerEvents: 'none' }}  className="accordion-content collapse">
                        <div className="pt-3">
                          <p>
                            Short answer - it depends. Long answer - it depends
                            on the complexity of your product, your goals, the
                            stage of the product development. Feel free to book
                            a call with us, so we can give you a more specific
                            estimate.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={toggleAccordion}
                      style={{ cursor: 'pointer' }}
                      id="panel-3"
                      className="card card-sm card-body card--no-hover text-left"
                    >
                      <div
                        style={{ pointerEvents: 'none' }}
                        className="accordion-panel-title"
                        aria-controls="panel-3"
                      >
                        <p aria-controls="panel-3">
                          <strong aria-controls="panel-3">
                            {" "}
                            Is every step of the design process outlined here
                            necessary?{" "}
                          </strong>
                        </p>
                        <DatoAsset
                          className="icon"
                          src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                          aria-controls="panel-3"
                        />
                      </div>

                      <div style={{ pointerEvents: 'none' }}  className="accordion-content collapse">
                        <div className="pt-3">
                          <p>
                            No. For example, there’s no need for an audit of an
                            app that hasn’t been designed yet. The steps we take
                            depend on our goals and what has already been done.
                            However, the sequence of design stages is always the
                            same.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={toggleAccordion}
                      style={{ cursor: 'pointer' }}
                      id="panel-4"
                      className="card card-sm card-body card--no-hover text-left"
                    >
                      <div
                        style={{ pointerEvents: 'none' }}
                        className="accordion-panel-title"
                        aria-controls="panel-4"
                      >
                        <p aria-controls="panel-4">
                          <strong aria-controls="panel-4">
                            {" "}
                            Is your design process compatible with lean and
                            agile methodologies?{" "}
                          </strong>
                        </p>
                        <DatoAsset
                          className="icon"
                          src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                          aria-controls="panel-4"
                        />
                      </div>

                      <div style={{ pointerEvents: 'none' }} className="accordion-content collapse">
                        <div className="pt-3">
                          <p>
                            Yes. in fact, the process outlined here constitutes
                            a single iteration.
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={toggleAccordion}
                      style={{ cursor: 'pointer' }}
                      id="panel-5"
                      className="card card-sm card-body card--no-hover text-left"
                    >
                      <div
                        style={{ pointerEvents: 'none' }}
                        className="accordion-panel-title"
                        aria-controls="panel-5"
                      >
                        <p aria-controls="panel-5">
                          <strong aria-controls="panel-5">
                            {" "}
                            Like our UX process and wondering what the next step
                            is?{" "}
                          </strong>
                        </p>
                        <DatoAsset
                          className="icon"
                          src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                          aria-controls="panel-3"
                        />
                      </div>
                      <div style={{ pointerEvents: 'none' }}  className="accordion-content collapse">
                        <div className="pt-3">
                          <p>
                            Feel free to{" "}
                            <a
                              href="https://adamfard.com/contact-us"
                              target="_blank"
                            >
                              book a call
                            </a>{" "}
                            with us to discuss how we can help you build your
                            product.
                          </p>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </section>
        </div>

        {/* <SectionOurBook /> */}

        {/* <section className="bg-primary-alt has-divider mt-4">
          <div className="divider">
            <Divider1 style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }} />
          </div>

        </section> */}
      </div>
    </Layout>
  );
};

// PDF Download Object
// const SectionOurBook = () => (
//   <section className="section-our-book text-light">
//     <div className="container">
//       <div className="row bg-primary-3 rounded px-4  align-items-center section-our-book__wrapper">
//         <div className="col-12 col-md-6 order-md-last text-center section-our-book__image">
//           <img
//             src="https://www.datocms-assets.com/16499/1579976839-ctauxdesignerinterviewquestions.png"
//             alt="hire ux designer interview questions"
//           />
//         </div>
//         <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
//           <div>
//             <span className="badge badge-primary-2">Free Template</span>
//             <h3 className="h1 my-2">Download UX Designer Interview Questions!</h3>
//             <p className="lead">
//               Here are 20 interview questions helping you to find the right candidate:
//             </p>
//             <a
//               href="https://adamfard.com/download-ux-resources/ux-designer-interview-questions"
//               className="btn btn-primary btn-lg mt-3 py-2"
//             >
//               Download Now
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   </section>
// );

// Header
const StickyPhaseHeader = ({ activePhase, scrollState }) => (
  <div className="sticky-wrapper" style={{ zIndex: 9991 }}>
    <div className="phase-reading-header layer-90">
      <div className="container py-0">
        <div className="phase-reading-header__wrapper">
          <div className="d-flex">
            {/* <small>Now reading: </small> <span>{activePhase}</span> */}
          </div>
          <div>
            {/* <strong>Free consultation: </strong> */}
            <span>Let's plan your next project</span>

            <a
              target="_blank"
              href="https://adamfard.com/ux-consulting"
              className="btn btn-primary ml-2"
            >
              Get UX Consultation
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="ml-2 py-0">
      <div className="row mt-2">
        <div className="col phases-menu rounded-phases">
          <nav className="navbar-nav">
            <a
              href="#audit"
              className={`my-1 pl-0 btn text-left ${
                scrollState.isFullyInViewport &&
                activePhase === "Phases / Section 1"
                  ? "active"
                  : ""
              }`}
            >
              <span>Understand & Audit</span>
            </a>

            <a
              href="#research"
              className={`my-1 btn pl-0 text-left ${
                (scrollState.isFullyInViewport ||
                  scrollState.isBelowViewport) &&
                activePhase === "Phases / Section 2"
                  ? "active"
                  : ""
              }`}
            >
              <span>Research</span>
            </a>

            <a
              href="#UX-strategy"
              className={`my-1 btn pl-0 text-left ${
                (scrollState.isFullyInViewport ||
                  scrollState.isBelowViewport) &&
                activePhase === "Phases / Section 3"
                  ? "active"
                  : ""
              }`}
            >
              <span>UX Strategy</span>
            </a>

            <a
              href="#UX-conception"
              className={`my-1 btn pl-0 text-left ${
                (scrollState.isFullyInViewport ||
                  scrollState.isBelowViewport) &&
                activePhase === "Phases / Section 4"
                  ? "active"
                  : ""
              }`}
            >
              <span>UX Conception</span>
            </a>

            <a
              href="#UI-design"
              className={`my-1 btn pl-0 text-left ${
                (scrollState.isFullyInViewport ||
                  scrollState.isBelowViewport) &&
                activePhase === "Phases / Section 5"
                  ? "active"
                  : ""
              }`}
            >
              <span>UI Design</span>
            </a>

            <a
              href="#QA-analysis"
              className={`my-1 btn pl-0 text-left ${
                (scrollState.isFullyInViewport ||
                  scrollState.isBelowViewport) &&
                activePhase === "Phases / Section 6"
                  ? "active"
                  : ""
              }`}
            >
              <span>QA & Analysis</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
);

// Side Menu
const WatchedPhaseSection = ({
  isDivider = false,
  bgColor = "bg-primary-alt",
  bgDivider2 = "bg-white",
  activePhase,
  phaseId,
  hidden = false,
  headerContent,
  mainContent
}) => {
  const ref = useRef(null);
  const scrollState = useScrollState(ref);

  return (
    <section
      id={phaseId}
      className={isDivider ? bgColor + " " + "has-divider" : bgColor}
      ref={ref}
    >
      {isDivider && (
        <div className="divider">
          <Divider1 style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }} />
        </div>
      )}
      {scrollState.isInViewport && !hidden && (
        <StickyPhaseHeader
          activePhase={activePhase}
          scrollState={scrollState}
        />
      )}

      <div className="header-content">{headerContent()}</div>

      {mainContent()}
    </section>
  );
};

export default UXDesignProcessPage;

export const query = graphql`
  query UXDesignProcessPage {
    datoCmsPage(slug: { eq: "ux-design-process" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }

    allDatoCmsArticle(limit: 3, sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          cardDescription
          cardImage {
            url
          }
          category {
            name
          }
          id
          slug
        }
      }
    }
  }
`;
